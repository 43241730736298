<template>
    <div>
        <div class="page-title">报名列表</div>

        <div class="cla-list">

            <template v-for="(item,ik) in list">
                <router-link :key="ik" class="cla-item" :to="`/ast/members/applies/${item.id}`">
                    <div class="cla-img">
                        <img :src="item.thumb" alt="">
                    </div>
                    <div class="cla-content">
                        <div class="cla-title">
                            <div class="group-card">{{ groups[item.group] }}</div>
                            <div class="cla-title-text">{{ item.title }}</div>
                        </div>
                        <div class="cla-val">
                            <div>
                                <div v-if="!item.pay_enabled" class="sub-item">支付</div>
                                <div v-else class="sub-item">支付单价</div>

                                <div class="sub-val">
                                    <span v-if="!item.pay_enabled">未开启</span>
                                    <span class="text-danger" v-else>￥{{ item.pay_amount }}</span>
                                </div>
                            </div>
                            <div>
                                <div class="sub-item">报名人数</div>
                                <div class="sub-val">{{ item.joins_count }}</div>
                            </div>
                            <div>
                                <div class="sub-item">未跟进人数</div>
                                <div class="sub-val">{{ item.waite_count }}</div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </template>

        </div>
    </div>
</template>

<script>
    import api from "../../../repo/api";

    export default {
        name: "Apply",
        data() {
            return {
                list: [],
                groups: {
                    activity: '活动',
                    serve: '服务',
                    courses: '课程',
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                api.get('/course/apply-list', (data) => {
                    this.list = data.data;
                })
            }
        },
    }
</script>

<style lang="less" scoped>

    .cla-item {

        width: 456px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        padding: 16px;

        display: flex;
        justify-content: left;
        flex-direction: row;

        margin-right: 32px;
        margin-bottom: 32px;
        cursor: pointer;

    }

    .cla-content {
        padding-top: 6px;
        flex: 1;
    }

    .cla-val {
        display: flex;
        justify-content: left;
        flex-direction: row;

        > div {
            margin-right: 24px;
        }
    }

    .cla-list {
        display: flex;
        justify-content: left;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .group-card {
        width: 32px;
        height: 16px;
        background: #333333;
        line-height: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        margin-right: 8px;
    }

    .sub-item {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-bottom: 8px;
    }

    .sub-val {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
    }

    .cla-title {
        display: flex;
        justify-content: left;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
    }

    .cla-title-text {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        height: 22px;
        overflow: hidden;

    }

    .cla-val > div:first-child {
        width: 78px;
        margin-right: 10px;
    }

    .cla-img {
        width: 96px;
        height: 96px;
        overflow: hidden;
        display: flex;
        align-items: center;
        background-color: #dddddd;
        margin-right: 16px;

        img {
            width: 100%;
        }
    }

</style>